import PageLayout from "../../compoments/layout/layout";
import HeaderDashboard from "../../compoments/Header";
import AdminSidebar from "../../compoments/layout/sidebar/admin";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowIcon } from "../../compoments/icons/ArrowIcon";
import { Col, Form, Row } from "react-bootstrap";
import SuccessFullRegisterModal from "../../compoments/modals/SuccessFullRegisterModal";
import { useEffect, useState } from "react";
import SelectBox from "../../compoments/SelectBox";
import { roleOption } from "../../Constant";
import { addressWithoutBuyerApi, registerBuyerApi } from "../../apies/buyerApi";
import { toast } from "react-toastify";
import Loader from "../../compoments/LoaderBtn";

const RegisterBuyer = () => {
  const navigate = useNavigate();
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    fullName:"",
    name: "",
    email: "",
    password: "",
    addressId: "",
  });

  const inputChnage = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (selectedOption, fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };
  useEffect(() => {
    const getAddressesApi = async () => {
      try {
        const res = await addressWithoutBuyerApi();
        const mapped = res.map((seller) => {
          return { value: seller._id, label: seller.name };
        });
        setAddressList(mapped);
      } catch (error) {
        console.log(error);
      }
    };
    getAddressesApi();
  }, [showRegisterModal]);
  const registerBuyer = async () => {
   
    setLoading(true);
    try {
      const res = await registerBuyerApi({ 
          name: formData.name,
          email: formData.email,
          password: formData.password,
          addressId:formData.addressId,
        role: "buyer" });

      console.log({res:res});
      
      if (res.status == 201) {
        setFormData({
          fullName:"",
          name: "",
          email: "",
          password: "",
          addressId: "",
        });
        setShowRegisterModal(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <>
    {loading && <Loader/> }
    <PageLayout>
      <section className="pageWrapper">
        <div className="sidebarContent">
          <AdminSidebar />
        </div>
        <section className="mainContent">
          <HeaderDashboard />
          <div className="customCard">
            <div className="pageBody">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                  <div
                    className="heading mb-2"
                    style={{ color: "var(--textColor)", fontSize: 14 }}
                  >
                    <Link
                      to={"/buyer/manage-buyer"}
                      style={{
                        color: "var(--textColor)",
                        fontSize: 14,
                        textDecoration: "none",
                      }}
                    >
                      Manage Buyers{" "}
                    </Link>
                    <span>
                      <ArrowIcon />
                    </span>
                    <span
                      className="mx-2 bold"
                      style={{ fontSize: "16px", color: "#000" }}
                    >
                      Register Buyer{" "}
                    </span>
                  </div>

                  <p className="mb-0">Register new buyer into the platform. </p>
                </div>
              </div>
              <hr className="mb-4" style={{ borderColor: "#bbb" }} />

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter full name of the seller"
                      name="fullName"
                      onChange={inputChnage}
                      value={formData.fullName}

                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData.name}
                      onChange={inputChnage}
                      placeholder="Set username for the seller"
                      name="name"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData.email}
                      placeholder="Enter email address of the seller"
                      name="email"
                      onChange={inputChnage}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Set Password</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData.password}
                      onChange={inputChnage}
                      placeholder="Set Password for the seller"
                      name="password"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Assigned Property</Form.Label>
                    <SelectBox
                      name="assignProperty"
                      value={formData.assignProperty}
                      handleChange={(selectedOption) =>
                        handleSelectChange(selectedOption.value, "addressId")
                      }
                      options={addressList}
                      defaultSelected={addressList[0]}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <button
                type="button"
                className="mt-3 btnPrimary"
                onClick={()=>registerBuyer()}
              >
                Register Buyer
              </button>
            </div>
          </div>
        </section>

        {showRegisterModal && (
          <SuccessFullRegisterModal
            show={showRegisterModal}
            onHide={() => setShowRegisterModal(false)}
            role={"buyer"}
          />
        )}
      </section>
    </PageLayout>
    </>
  );
};

export default RegisterBuyer;
