import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import SplashScreen from "./splash";
import NotFound from "./NotFound";
import ManageEstateAgent from "./EstateAgent/manage-estate-agent";
import ViewAgentDetails from "./EstateAgent/view-agent-detail";
import ViewDocuments from "./EstateAgent/view-documents";
import AssignProperty from "./EstateAgent/property-assign";
import RegisterAgent from "./EstateAgent/register-estate-agent";
import ManageSolicitor from "./Solicitor/manage-solicitor";
import ViewSolicitorDetails from "./Solicitor/view-solicitor-detail";
import ViewSolicitorDocuments from "./Solicitor/view-documents";
import RegisterSolicitor from "./Solicitor/register-solicitor";
import ManageSeller from "./Seller/manage-seller";
import ViewSellerDocuments from "./Seller/view-seller-detail";
import RegisterSeller from "./Seller/register-seller";
import ManageBuyer from "./buyer/manage-buyer";
import ViewBuyerDocuments from "./buyer/view-seller-detail";
import RegisterBuyer from "./buyer/register-buyer";
import ManageProperties from "./manage-property/manage-properties";
import ViewPropertyDocuments from "./manage-property/view-property-detail";
import RegisterProperty from "./manage-property/register-property";
import ProtectedRoute from './compoments/ProtectedRoute';
import PublicRoute from './compoments/PublicRoute'

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={
          <PublicRoute>
            <SplashScreen />
          </PublicRoute>
        } />
        {/* Private Rotues */}
        <Route path="/agent/manage-estate-agent" element={
          <ProtectedRoute>
            <ManageEstateAgent />
          </ProtectedRoute>
        } />
        <Route path="/agent/view-agent-detail" element={
          <ProtectedRoute>
            <ViewAgentDetails />
          </ProtectedRoute>
        } />
        <Route path="/agent/view-documents" element={
          <ProtectedRoute>
            <ViewDocuments />
          </ProtectedRoute>
        } />
        <Route path="/agent/assign-property" element={
          <ProtectedRoute>
            <AssignProperty />
          </ProtectedRoute>
        } />
        <Route path="/agent/register-estate-agent" element={
          <ProtectedRoute>

            <RegisterAgent />
          </ProtectedRoute>
        } />

        <Route path="/solicitor/manage-solicitor" element={
          <ProtectedRoute>
            <ManageSolicitor />
          </ProtectedRoute>
        } />
        <Route path="/solicitor/view-solicitor-detail" element={
          <ProtectedRoute>
            <ViewSolicitorDetails />
          </ProtectedRoute>
        } />
        <Route path="/solicitor/view-solicitor-documents" element={
          <ProtectedRoute>
            <ViewSolicitorDocuments />
          </ProtectedRoute>
        } />
        <Route path="/solicitor/register-solicitor" element={
          <ProtectedRoute>
            <RegisterSolicitor />
          </ProtectedRoute>
        } />

        <Route path="/seller/manage-seller" element={
          <ProtectedRoute>
            <ManageSeller />
          </ProtectedRoute>
        } />
        <Route path="/seller/view-seller-detail" element={
          <ProtectedRoute>
            <ViewSellerDocuments />
          </ProtectedRoute>
        } />
        <Route path="/seller/register-seller" element={
          <ProtectedRoute>
            <RegisterSeller />
          </ProtectedRoute>
        } />

        <Route path="/buyer/manage-buyer" element={
          <ProtectedRoute>
            <ManageBuyer />
          </ProtectedRoute>
        } />
        <Route path="/buyer/view-buyer-detail" element={
          <ProtectedRoute>
            <ViewBuyerDocuments />
          </ProtectedRoute>
        } />
        <Route path="/buyer/register-buyer" element={
          <ProtectedRoute>
            <RegisterBuyer />
          </ProtectedRoute>
        } />

        <Route path="/property/manage-property" element={
          <ProtectedRoute>
            <ManageProperties />
          </ProtectedRoute>
        } />
        <Route path="/property/view-property-detail" element={
          <ProtectedRoute>
            <ViewPropertyDocuments />
          </ProtectedRoute>
        } />
        <Route path="/property/register-property" element={
          <ProtectedRoute>
            <RegisterProperty />
          </ProtectedRoute>
        } />


        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
