import PageLayout from "../../compoments/layout/layout";
import HeaderDashboard from "../../compoments/Header";
import AdminSidebar from "../../compoments/layout/sidebar/admin";
import DataTable from "react-data-table-component";
import { ViewSolicitorDetailsColumn } from "../../Constant";
import { AdminView } from "../../compoments/icons/AdminView";
import { PlusIcon } from "../../compoments/icons/PlusIcon";
import { ArrowIcon } from "../../compoments/icons/ArrowIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { solicitorAddressApi } from "../../apies/solicitorApi";
import { useEffect, useState } from "react";
import Loader from "../../compoments/LoaderBtn";

const ViewSolicitorDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { solicitorName, solicitorId } = location.state;
  const [addresses,setAddresses] = useState([])

  useEffect(() => {
    const getAddressForSolicitorFUn = async ()=>{
    try {
      const response = await solicitorAddressApi(solicitorId);
      const mapped = response.map((sol,i)=>{
        return(
          {
            srNo: i+1,
            propertyLocation: sol?.address?.name,
            seller: sol?.seller?.name,
            buyer: sol?.buyer?.name ||'-',
            estateAgent: sol?.agent?.name ||'-',
            price: sol?.address?.price,
            action: (
              <>
                  <button
                    className="btnViewDetail me-2"
                    onClick={() => navigate("/solicitor/view-solicitor-documents",{state:{
                      addressName:sol?.address?.name,
                      addressId:sol?.address?.id,
                      solicitorName:solicitorName,
                    }})}
                  >
                    <span>
                      <AdminView />
                    </span>
                    View Documents
                  </button>
                
              </>
            ),
          }
        )
      })
      setAddresses(mapped)
    } catch (error) {
      console.log(error)
    }
    }
    getAddressForSolicitorFUn()
  },[])

  const data = [];
  for (let i = 1; i <= 1; i++) {
    data.push(
      {
      srNo: i,
      propertyLocation: `5dw21 Block C, Street ${i}, South Bucks, UK `,
      seller: `Willson James`,
      buyer: `Daniel David`,
      estateAgent: `Daniel David`,
      price: `$1,000,000`,
      action: (
        <>
            <button
              className="btnViewDetail me-2"
              onClick={() => navigate("/solicitor/view-solicitor-documents")}
            >
              <span>
                <AdminView />
              </span>
              View Documents
            </button>
          
        </>
      ),
    });
  }

  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false)
  }, 1500);
  return (
    <>
    {loading && <Loader /> }
    <PageLayout>
      <section className="pageWrapper">
        <div className="sidebarContent">
          <AdminSidebar />
        </div>
        <section className="mainContent">
          <HeaderDashboard />
          <div className="customCard">
            <div className="pageBody">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                  <div
                    className="heading mb-1"
                    style={{ color: "var(--textColor)", fontSize: 14 }}
                  >
                     <Link
                        to={"/solicitor/manage-solicitor"}
                        style={{
                          color: "var(--textColor)",
                          fontSize: 14,
                          textDecoration: "none",
                        }}
                      >
                        {" "}
                        Manage Estate Agents{" "}
                      </Link>
                    <span>
                      <ArrowIcon />
                    </span>
                    <span
                      className="mx-2 bold text-capitalize"
                      style={{ fontSize: "16px", color: "#000" }}
                    >
                      {solicitorName}
                    </span>
                  </div>
                  <p className="mb-0">
                  View property details managed by the solicitor. 
                  </p>
                </div>
                <div>
                  <button className="btnPrimary" onClick={()=>navigate('/solicitor/register-solicitor')}>
                    <span className="me-2">
                      <PlusIcon />
                    </span>
                    Register Solicitor
                  </button>
                </div>
              </div>

              <div className="dataTableContainer mt-4">
                  <DataTable columns={ViewSolicitorDetailsColumn} data={addresses} />
              </div>
            </div>
          </div>
        </section>
      </section>
    </PageLayout>
    </>
  );
};

export default ViewSolicitorDetails;
