import PageLayout from "../../compoments/layout/layout";
import HeaderDashboard from "../../compoments/Header";
import AdminSidebar from "../../compoments/layout/sidebar/admin";
import DataTable from "react-data-table-component";
import { ManageSolicitorsColumn } from "../../Constant";
import { AdminTrash } from "../../compoments/icons/AdminTrash";
import { AdminView } from "../../compoments/icons/AdminView";
import { PlusIcon } from "../../compoments/icons/PlusIcon";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DeleteModal from "../../compoments/modals/DeleteModal";
import { deleteSolicitorApi, listSolictorApis } from "../../apies/solicitorApi";
import { toast } from "react-toastify";
import Loader from "../../compoments/LoaderBtn";

const ManageSolicitor = () => {
  const [deleteSolicitor, setDeleteSolicitor] = useState(false);
  const navigate = useNavigate();
  const [solicitorList, setSolicitorList] = useState([]);
  const [solId, setSolId] = useState(null);
  const data = [];
  useEffect(() => {
    const getSolicitorFun = async () => {
      try {
        const response = await listSolictorApis();

        const mapped = response.solicitors.map((sol, i) => {
          return {
            srNo: i + 1,
            name: sol.name,
            email: sol.email,
            associatedWithSeller: sol?.seller?.name || "-",
            manageProperty: (
              <>
                {sol.addresses.length > 0 && (
                  <button
                    className="btnViewDetail me-2"
                    onClick={() =>
                      navigate("/solicitor/view-solicitor-detail", {
                        state: {
                          solicitorName: sol?.name,
                          solicitorId: sol?._id,
                        },
                      })
                    }
                  >
                    <span>
                      <AdminView />
                    </span>
                    View Details
                  </button>
                )}
              </>
            ),
            action: (
              <button
                className="btnDeleteAgent me-2"
                onClick={() => {
                  setSolId(sol._id);
                  setDeleteSolicitor(true);
                }}
              >
                <span>
                  <AdminTrash />
                </span>
                Delete Solicitor
              </button>
            ),
          };
        });
        setSolicitorList(mapped);
      } catch (error) {
        console.log(error);
      }
    };
    getSolicitorFun();
  }, [deleteSolicitor]);

  const deleteSolicitorFun = async () => {
    try {
      const response = await deleteSolicitorApi(solId);
      if (response.status === 200) {
        setDeleteSolicitor(false);
        toast.success(response.message);
      } else {
        const errorData = await response.json();
        toast.error(errorData.message);
      }
    } catch (error) {
      console.error("An error occurred:", error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };

  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false)
  }, 1500);
  return (
    <>
    {loading && <Loader/> }
    <PageLayout>
      <section className="pageWrapper">
        <div className="sidebarContent">
          <AdminSidebar />
        </div>
        <section className="mainContent">
          <HeaderDashboard />
          <div className="customCard">
            <div className="pageBody">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                  <div className="heading mb-1">Manage Solicitors</div>
                  <p className="mb-0">
                    As an admin, you are allowed to manage solicitors and view
                    their activities across each property.
                  </p>
                </div>
                <div>
                  <button
                    className="btnPrimary"
                    onClick={() => navigate("/solicitor/register-solicitor")}
                  >
                    <span className="me-2">
                      <PlusIcon />
                    </span>
                    Register Solicitor
                  </button>
                </div>
              </div>
              <div className="dataTableContainer mt-4">
                <DataTable
                  columns={ManageSolicitorsColumn}
                  data={solicitorList}
                />
              </div>
            </div>
          </div>
        </section>

        {deleteSolicitor && (
          <DeleteModal
            show={deleteSolicitor}
            onHide={() => setDeleteSolicitor(false)}
            title="Delete Solicitor"
            onDelete={() => deleteSolicitorFun()}
          />
        )}
      </section>
    </PageLayout>
    </>
  );
};

export default ManageSolicitor;
