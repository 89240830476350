export const AdminProperty = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6693 14.1668H14.0026V6.65348C14.0026 6.24014 13.8159 5.85348 13.4893 5.60014L12.6693 4.96014L12.6559 3.32681C12.6559 2.96014 12.3559 2.66681 11.9893 2.66681H9.71594L8.8226 1.97348C8.3426 1.59348 7.6626 1.59348 7.1826 1.97348L2.51594 5.60014C2.18927 5.85348 2.0026 6.24014 2.0026 6.64681L1.96927 14.1668H1.33594C1.0626 14.1668 0.835938 14.3935 0.835938 14.6668C0.835938 14.9401 1.0626 15.1668 1.33594 15.1668H14.6693C14.9426 15.1668 15.1693 14.9401 15.1693 14.6668C15.1693 14.3935 14.9426 14.1668 14.6693 14.1668ZM4.33594 8.50014V7.50014C4.33594 7.13348 4.63594 6.83348 5.0026 6.83348H6.33594C6.7026 6.83348 7.0026 7.13348 7.0026 7.50014V8.50014C7.0026 8.86681 6.7026 9.16681 6.33594 9.16681H5.0026C4.63594 9.16681 4.33594 8.86681 4.33594 8.50014ZM9.66927 14.1668H6.33594V12.3335C6.33594 11.7801 6.7826 11.3335 7.33594 11.3335H8.66927C9.2226 11.3335 9.66927 11.7801 9.66927 12.3335V14.1668ZM11.6693 8.50014C11.6693 8.86681 11.3693 9.16681 11.0026 9.16681H9.66927C9.3026 9.16681 9.0026 8.86681 9.0026 8.50014V7.50014C9.0026 7.13348 9.3026 6.83348 9.66927 6.83348H11.0026C11.3693 6.83348 11.6693 7.13348 11.6693 7.50014V8.50014Z"
        fill="#D39927"
      />
    </svg>
  );
};
