import PageLayout from "../../compoments/layout/layout";
import HeaderDashboard from "../../compoments/Header";
import AdminSidebar from "../../compoments/layout/sidebar/admin";
import DataTable from "react-data-table-component";
import { ManageBuyerColumn } from "../../Constant";
import { AdminView } from "../../compoments/icons/AdminView";
import { PlusIcon } from "../../compoments/icons/PlusIcon";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { listOfBuyersApis } from "../../apies/buyerApi";
import Loader from "../../compoments/LoaderBtn";

const ManageBuyer = () => {
  const navigate = useNavigate();
  const [buyerList, setBuyerList] = useState([])
  useEffect(() => {
    const getBuyersFun = async ()=>{
    try {
      const response = await listOfBuyersApis();
      const mapped = response.map((buyer,i)=>{
        return(
          {
            srNo: i+1,
            name:buyer?.buyer?.name,
            solicitor: buyer.address?.solicitor?.name || '-',
            estateAgent: buyer.address?.estateAgent?.name ||'-',
            email: buyer?.buyer?.email,
            propertyLoaction: buyer?.address?.name ||'-',
            action: (
              <div className="d-flex align-items-center g-3">
                {buyer?.address &&<button
                  className="btnViewDetail me-2"
                  onClick={() => navigate("/buyer/view-buyer-detail",{state:{
                    addressName:buyer?.address?.name,
                    addressId:buyer?.address?._id,
                    buyerName:buyer?.buyer?.name,
                  }})}
                >
                  <span>
                    <AdminView />
                  </span>
                  View
                </button>}
              </div>
            ),
          }
        )
      })
      setBuyerList(mapped)
    } catch (error) {
      console.log(error)
    }
    }
    getBuyersFun()
  },[])

  const [loading, setLoading] = useState(true)
  setTimeout(() => {
    setLoading(false)
  }, 1000);

  return (
    <>
    {loading && <Loader /> }
    <PageLayout>
      <section className="pageWrapper">
        <div className="sidebarContent">
          <AdminSidebar />
        </div>
        <section className="mainContent">
          <HeaderDashboard />
          <div className="customCard">
            <div className="pageBody">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                  <div className="heading mb-1">Manage Buyers</div>
                  <p className="mb-0">
                    As an admin, you are allowed to manage buyers and view their
                    activities across each property.{" "}
                  </p>
                </div>
                <div>
                  <button
                    className="btnPrimary"
                    onClick={() => navigate("/buyer/register-buyer")}
                  >
                    <span className="me-2">
                      <PlusIcon />
                    </span>
                    Register Buyer
                  </button>
                </div>
              </div>
              <div className="dataTableContainer mt-4">
                <DataTable columns={ManageBuyerColumn} data={buyerList} />
              </div>
            </div>
          </div>
        </section>
      </section>
    </PageLayout>
    </>
  );
};

export default ManageBuyer;
