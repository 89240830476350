import PageLayout from "../../compoments/layout/layout";
import HeaderDashboard from "../../compoments/Header";
import AdminSidebar from "../../compoments/layout/sidebar/admin";
import DataTable from "react-data-table-component";
import { docTypes, ViewAgentDetailsColumn } from "../../Constant";
import { AdminView } from "../../compoments/icons/AdminView";
import { PlusIcon } from "../../compoments/icons/PlusIcon";
import { ArrowIcon } from "../../compoments/icons/ArrowIcon";
import { useState, us, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { EyeIcon } from "../../compoments/icons/EyeIcon";
import { WarnIcon } from "../../compoments/icons/WarnIcon";
import PreviewFileModal from "../../compoments/modals/PreviewFileModal";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { solicitorAddressApi } from "../../apies/solicitorApi";
import { docAgainstAddressApi } from "../../apies/commonApies";
import Loader from "../../compoments/LoaderBtn";

const ViewDocuments = () => {
  const [previewModal, setPreviewModal] = useState(false);
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { agentName, addressName, addressId } = location.state;
  const [addresses, setAddresses] = useState([]);
  const [docList, setDocList] = useState([]);
  const [unavailableDoc, setUnavailableDoc] = useState([]);

  useEffect(() => {
    const getDocsFun = async () => {
      const res = await docAgainstAddressApi(addressId);
      // available doc types
      const availableDoc = res.documents.map((i) => i.docType);
      // unavailable doc types
      const unaavailable = docTypes.filter((i) => !availableDoc.includes(i));
      setUnavailableDoc(unaavailable);
      setDocList(res.documents);
    };
    getDocsFun();
  }, []);
  setTimeout(() => {
    setLoading(false);
  }, 1000);
  return (
    <>
    {loading && <Loader /> }
    <PageLayout>
      <section className="pageWrapper">
        <div className="sidebarContent">
          <AdminSidebar />
        </div>
        <section className="mainContent">
          <HeaderDashboard />
          <div className="customCard">
            <div className="pageBody">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                  <div
                    className="heading mb-1"
                    style={{ color: "var(--textColor)", fontSize: 14 }}
                  >
                    <Link
                      to={"/agent/manage-estate-agent"}
                      style={{
                        color: "var(--textColor)",
                        fontSize: 14,
                        textDecoration: "none",
                      }}
                    >
                      {" "}
                      Manage Estate Agents{" "}
                    </Link>
                    <span>
                      <ArrowIcon />
                    </span>
                    <span className="mx-2 bold text-capitalize pointer" onClick={()=>navigate(-1)}>
                     
                        {agentName}
                    </span>
                    <span>
                      <ArrowIcon />
                    </span>
                    <span
                      className="mx-2 bold text-capitalize"
                      style={{ fontSize: "16px", color: "#000" }}
                    >
                      {addressName}
                    </span>
                  </div>
                  <p className="mb-0">
                    View estate agent profile and the properties managed by that
                    agent.
                  </p>
                </div>
              </div>
              <hr className="mb-3" style={{ borderColor: "#bbb" }} />

              <div>
                <div className="heading mb-1">Documents</div>
                <p className="mb-0">
                  These are the documents that needs verification from the
                  associated estate agent.
                </p>
              </div>

              <Row className="mt-4">
                {[...docList, ...unavailableDoc].map((doc) => {
                  return (
                    <Col key={doc._id} md={3} className="mb-3">
                      <div className="documentCard">
                        <div
                          className={
                            typeof doc !== "object"
                              ? "customCardYellow"
                              : doc.isEdit
                              ? "customCardDark"
                              : "customCardRed"
                          }
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setFile(doc?.url);
                            setFileName(doc.originalName);
                            setPreviewModal(true);
                            // setTitle(item.docType);
                          }}
                        >
                          {typeof doc !== "object" ? <WarnIcon /> : <EyeIcon />}
                          {typeof doc === "object" ? (
                            <p className="mb-1 mt-2">Preview PDF File</p>
                          ) : (
                            <p className="mb-1 mt-2">No File Uploaded</p>
                          )}
                        </div>
                      </div>
                      <div className="cardName mt-1 py-2 d-flex align-items-center justify-content-center">
                        {typeof doc === "object" ? doc?.docType : doc}
                        {typeof doc === "object" && !doc.isEdit && (
                          <span className="text-red mx-1">
                            {" "}
                            (Needs Approval)
                          </span>
                        )}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </section>
      </section>

      {previewModal && (
        <PreviewFileModal
          show={previewModal}
          onHide={() => setPreviewModal(false)}
          //   file={file}
          //   download={() => handleDownload(file, fileName)}
          //   btnLoading={btnLoading}
          //   title={title}
        />
      )}
    </PageLayout>
    </>
  );
};

export default ViewDocuments;
