import PageLayout from "../../compoments/layout/layout";
import HeaderDashboard from "../../compoments/Header";
import AdminSidebar from "../../compoments/layout/sidebar/admin";
import { useNavigate } from "react-router-dom";
import { ArrowIcon } from "../../compoments/icons/ArrowIcon";
import { Col, Form, Row } from "react-bootstrap";
import SuccessFullRegisterModal from "../../compoments/modals/SuccessFullRegisterModal";
import { useEffect, useState } from "react";
import SelectBox from "../../compoments/SelectBox";
import { roleOption } from "../../Constant";
import {
  registerSolicitor,
  sellersWithNoSolicitorApi,
} from "../../apies/solicitorApi";
import { toast } from "react-toastify";
import Loader from "../../compoments/LoaderBtn";

const RegisterSolicitor = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [formData, setFormData] = useState({
    fullName:"",
    name: "",
    email: "",
    password: "",
  });
  const [sellers, setSellers] = useState([]);

  const inputChnage = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSelectChange = (selectedOption, fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };
  useEffect(() => {
    const getAddressesApi = async () => {
      try {
        const res = await sellersWithNoSolicitorApi();
        const mapped = res.users.map((seller) => {
          return { value: seller._id, label: seller.name };
        });
        setSellers(mapped);
      } catch (error) {
        console.log(error);
      }
    };
    getAddressesApi();
  }, [showRegisterModal]);
  console.log("fomr", formData);

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault(); // Prevent default form submission behavior

    try {
      const response = await registerSolicitor({
        name: formData.name,
        email: formData.email,
        password: formData.password,
        sellerId: formData.sellerId,
      });
      if (response.status === 201) {
        setShowRegisterModal(true);
        setFormData({
          fullName:"",
          name: "",
          email: "",
          password: "",
          sellerId: "",
        });
        setLoading(false)
      } else {
        setLoading(false)
        const errorData = await response.json();
        toast.error(errorData.message);
      }
    } catch (error) {
      setLoading(false)
      console.error("An error occurred:", error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <PageLayout>
        <section className="pageWrapper">
          <div className="sidebarContent">
            <AdminSidebar />
          </div>
          <section className="mainContent">
            <HeaderDashboard />
            <div className="customCard">
              <div className="pageBody">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div>
                    <div
                      className="heading mb-2"
                      style={{ color: "var(--textColor)", fontSize: 14 }}
                    >
                      Manage Solicitor{" "}
                      <span>
                        <ArrowIcon />
                      </span>
                      <span
                        className="mx-2 bold"
                        style={{ fontSize: "16px", color: "#000" }}
                      >
                        Register Solicitor{" "}
                      </span>
                    </div>

                    <p className="mb-0">
                      Register new solicitor into the platform and let them
                      manage properties for a seamless flow.
                    </p>
                  </div>
                </div>
                <hr className="mb-4" style={{ borderColor: "#bbb" }} />

                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                         name="fullName"
                         type="text"
                         placeholder="Enter full name of the solicitor"
                         value={formData.fullName}
                        onChange={inputChnage}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Set username for the solicitor"
                        value={formData.name}
                        onChange={inputChnage}
                        name="name"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Associated Seller</Form.Label>
                      <SelectBox
                        name="assosiatedSeller"
                        value={formData.sellerId}
                        handleChange={(selectedOption) =>
                          handleSelectChange(selectedOption.value, "sellerId")
                        }
                        options={sellers}
                        // defaultSelected={sellers[0]}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="text"
                        value={formData.email}
                        placeholder="Enter email address of the solicitor"
                        name="email"
                        onChange={inputChnage}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Set Password</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={inputChnage}
                        placeholder="Set Password for the solicitor"
                        name="password"
                        value={formData.password}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <button
                  type="button"
                  className="mt-3 btnPrimary"
                  onClick={handleSubmit}
                >
                  Register Solicitor
                </button>
              </div>
            </div>
          </section>

          {showRegisterModal && (
            <SuccessFullRegisterModal
              show={showRegisterModal}
              onHide={() => setShowRegisterModal(false)}
              role="solicitor"
            />
          )}
        </section>
      </PageLayout>
    </>
  );
};

export default RegisterSolicitor;
