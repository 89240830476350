import { useState } from "react";
import Step1 from "./partials/Step1";

const steps = [
  Step1
];

const SplashScreen = () => {
  const [activeStep] = useState(1);
  const StepComponent = steps[activeStep - 1];

  return (
    <>
      <StepComponent />
    </>
  );
};

export default SplashScreen;
