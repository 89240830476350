import { Modal } from "react-bootstrap";
import { TickIcon } from "../icons/TickIcon";

const SuccessFullRegisterModal = ({ show, onHide, role }) => {
  return (
    <>
      <Modal
        show={show}
        className="uploadModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4>
            <span className="me-2">
              <TickIcon />
            </span>
            Successfully Registered!{" "}
          </h4>
          <p>
            An {role} has been successfully registered on the signed
            sealed sold platform.{" "}
          </p>

          <div className=" mt-3 ">
            <button className="btnGray w-100" onClick={onHide}>
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuccessFullRegisterModal;
