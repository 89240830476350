import { Modal } from "react-bootstrap";
import { UploadIcon } from "../icons/UploadIcon";
import { HomeProperty } from "../icons/HomeProperty";

const AssignModal = ({
  show,
  onHide,
  submit
}) => {
  return (
    <>
      <Modal
        show={show}
        className="uploadModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4>Assign Property!</h4>
          <p>
            Are you sure you want to assign the
            <span style={{ color: "#000" }}>
              “521 Block C, Street 3, South Bucks, UK”
            </span>{" "}
            property to Jenny Wales? Please confirm your action.{" "}
          </p>

          <div className="d-flex align-items-center mt-3 justify-content-center">
            <button className="btnGray" onClick={onHide}>
              Cancel
            </button>
            <button  onClick={submit} className="btnPrimary d-flex align-items-center">
              <span className="me-2" style={{marginTop:'-4px'}}>
                <HomeProperty/>
              </span>
              Assign Property
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AssignModal;
