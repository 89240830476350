// src/features/userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {loginApi} from '../apies/authApi'
import { toast } from "react-toastify";

// Async thunk for login action
export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (userCredentials, { rejectWithValue }) => {
    try {
      // Assume this is your API request for login
      const data = await loginApi(userCredentials);
      return data; // Assuming response.data contains user details like { name, email, role }
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle error response
    }
  }
);

// Initial state
const initialState = {
  name: '',
  email: '',
  role: '',
  token:'',
  loading: false, 
  error: null,   
};

// User slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Handle logout separately
    logout: (state) => {
      state.name = '';
      state.email = '';
      state.role = '';
      state.loading = false;
      state.error = null;
      state.token=null
    },
  },
  extraReducers: (builder) => {
    // Handle pending, fulfilled, and rejected cases for loginUser
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true; // Set loading to true when login starts
        state.error = null;    // Clear previous errors
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        // On success, set user data and mark authenticated
        const { token } = action.payload;
        const {name, email, role} = action?.payload?.user
        state.name = name;
        state.email = email;
        state.role = role;
        state.token =token ;
        state.loading = false;
        localStorage.setItem('token',token)
        
        toast.success(action.payload.message)
      })
      .addCase(loginUser.rejected, (state, action) => {
        console.log('action.payload',action.payload)
        state.loading = false; // Set loading to false when login fails
        state.error = action?.payload?.message || 'Something went wrong'; // Capture error message
        toast.error(action?.payload?.message)

      });
  },
});

// Export logout action
export const { logout, } = userSlice.actions;

// Export reducer
export default userSlice.reducer;
