import PageLayout from "../../compoments/layout/layout";
import HeaderDashboard from "../../compoments/Header";
import AdminSidebar from "../../compoments/layout/sidebar/admin";
import DataTable from "react-data-table-component";
import { AdminTrash } from "../../compoments/icons/AdminTrash";
import { AdminView } from "../../compoments/icons/AdminView";
import { AdminProperty } from "../../compoments/icons/AdminProperty";
import { PlusIcon } from "../../compoments/icons/PlusIcon";
import { Link, useNavigate } from "react-router-dom";
import { ArrowIcon } from "../../compoments/icons/ArrowIcon";
import { Col, Form, Row } from "react-bootstrap";
import SuccessFullRegisterModal from "../../compoments/modals/SuccessFullRegisterModal";
import { useState } from "react";
import { registerUserApi } from "../../apies/commonApies";
import { toast } from "react-toastify";
import Loader from "../../compoments/LoaderBtn";

const RegisterAgent = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [formData, setFormData] = useState({
    fullName:"",
    name: "",
    email: "",
    password: "",
    role: "estateAgent",
  });

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true)
    try {
      const response = await registerUserApi({
        name: formData.name,
        email: formData.email,
        password: formData.password,
        role: "estateAgent",
      });
      if (response) {
        setLoading(false)
        toast.success(
          response?.message || "Estate Agent resigtered successfully"
        );
        // Handle successful registration (e.g., show modal or redirect)
        setShowRegisterModal(true);
        setFormData({
          fullName:"",
          name: "",
          email: "",
          password: "",
          role: "estateAgent",
        });
      } else {
        const errorData = await response.json();
        toast.error(errorData.message);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error("An error occurred:", error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <>
    {loading && <Loader/> }
    <PageLayout>
      <section className="pageWrapper">
        <div className="sidebarContent">
          <AdminSidebar />
        </div>
        <section className="mainContent">
          <HeaderDashboard />
          <div className="customCard">
            <div className="pageBody">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                  <div
                    className="heading mb-2"
                    style={{ color: "var(--textColor)", fontSize: 14 }}
                  >
                    <Link
                      to={"/agent/manage-estate-agent"}
                      style={{
                        color: "var(--textColor)",
                        fontSize: 14,
                        textDecoration: "none",
                      }}
                    >
                      {" "}
                      Manage Estate Agents{" "}
                    </Link>
                    <span>
                      <ArrowIcon />
                    </span>
                    <span
                      className="mx-2 bold"
                      style={{ fontSize: "16px", color: "#000" }}
                    >
                      Register Estate Agent{" "}
                    </span>
                  </div>

                  <p className="mb-0">
                    Register new estate agent into the platform and let them
                    manage properties for a seamless flow.
                  </p>
                </div>
              </div>
              <hr className="mb-4" style={{ borderColor: "#bbb" }} />

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter full name of the agent"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleInputChange}

                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Set username for the agent"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter email address of the agent"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Set Password</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Set Password for the agent"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <button
                type="button"
                className="mt-3 btnPrimary"
                onClick={handleSubmit}
              >
                Register Estate Agent
              </button>
            </div>
          </div>
        </section>
      </section>

      {showRegisterModal && (
          <SuccessFullRegisterModal
            show={showRegisterModal}
            onHide={() => setShowRegisterModal(false)}
            role="estate agent"
          />
        )}
    </PageLayout>
    </>
  );
};

export default RegisterAgent;
