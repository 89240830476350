import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children }) => {
  const {  token } = useSelector((state) => state.user); // Check if user is logged in
  const isAuthenticated =  token;

  // If not authenticated, redirect to login page
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  // If authenticated, render the protected route
  return children;
};

export default ProtectedRoute;