import PageLayout from "../../compoments/layout/layout";
import HeaderDashboard from "../../compoments/Header";
import AdminSidebar from "../../compoments/layout/sidebar/admin";
import DataTable from "react-data-table-component";
import { ManagePropertyColumn } from "../../Constant";
import { AdminTrash } from "../../compoments/icons/AdminTrash";
import { AdminView } from "../../compoments/icons/AdminView";
import { PlusIcon } from "../../compoments/icons/PlusIcon";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { listOfPropertiesApi } from "../../apies/proptertApies";
import Loader from "../../compoments/LoaderBtn";

const ManageProperties = () => {
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  useEffect(() => {
    const getPropertiesFun = async () => {
      try {
        const res = await listOfPropertiesApi();
  
        const maped = res.map((address, i) => {
          console.log('ddress?.estateAgent?.name',address?.estateAgent?.name)
          return {
            srNo: i + 1,
            propertyLoaction: address.name,
            seller: address?.seller?.name,
            buyer: address?.buyer?.name? address?.buyer?.name:"-",
            solicitor: address?.seller?.solicitor?.name?address?.seller?.solicitor?.name:'-',
            estateAgent: address?.estateAgent?.name?address?.estateAgent?.name:'-',
            price: '$' +address?.price,
            action: (
              <div className="d-flex align-items-center g-3">
                <button
                  className="btnViewDetail me-2"
                  onClick={() => navigate("/property/view-property-detail",{
                    state: {
                      addressName:address.name,
                      addressId:address._id
                    },
                  })}
                >
                  <span>
                    <AdminView />
                  </span>
                  View Documents
                </button>
              </div>
            ),
          };
        });
        setProperties(maped);
      } catch (error) {
        console.log(error);
      }
    };
    getPropertiesFun();
  }, []);



  const [loading, setLoading] = useState(true)
  setTimeout(() => {
    setLoading(false)
  }, 1000);
  return (
    <>
    {loading && <Loader/> }
    <PageLayout>
      <section className="pageWrapper">
        <div className="sidebarContent">
          <AdminSidebar />
        </div>
        <section className="mainContent">
          <HeaderDashboard />
          <div className="customCard">
            <div className="pageBody">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                  <div className="heading mb-2">Manage Properties</div>
                  <p className="mb-0">
                    As an admin, you are allowed to manage properties.
                  </p>
                </div>
                <div>
                  <button
                    className="btnPrimary"
                    onClick={() => navigate("/property/register-property")}
                  >
                    <span className="me-2">
                      <PlusIcon />
                    </span>
                    Register Property
                  </button>
                </div>
              </div>
              <div className="dataTableContainer mt-4">
                <DataTable columns={ManagePropertyColumn} data={properties} />
              </div>
            </div>
          </div>
        </section>
      </section>
    </PageLayout>
    </>
  );
};

export default ManageProperties;
