import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PublicRoute = ({ children }) => {
  const { token } = useSelector((state) => state.user); // Check if user is logged in
  const isAuthenticated = token;

  // If authenticated, redirect to dashboard or other protected page
  if (isAuthenticated) {
    return <Navigate to="/agent/manage-estate-agent" replace />;
  }

  // If not authenticated, render the public route (e.g., login or signup page)
  return children;
};

export default PublicRoute;
