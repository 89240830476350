import apiClient from ".";

// list of buyers and their details
export const listOfBuyersApis =  async () => {
  const response = await apiClient.get(`admin/buyers`);
  return response.data;
};

// get list of address where buyer is not assigned
export const addressWithoutBuyerApi =  async () => {
    const response = await apiClient.get(`admin/addresses/no-buyer`);
    return response.data;
  };


  // register a buyer
export const registerBuyerApi =  async (body) => {
  const response = await apiClient.post(`admin/buyer/register`,{...body});
  return response.data;
}
  
