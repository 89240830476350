import { Image } from "react-bootstrap";
import Logo from "./../../../../assets/images/logo.svg";
import { useLocation, useNavigate } from "react-router-dom";

const AdminSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const isAgentRoute = location.pathname.includes("/agent");
  const isSolicitorRoute = location.pathname.includes("/solicitor");
  const isSellerRoute = location.pathname.includes("/seller");
  const isBuyerRoute = location.pathname.includes("/buyer");
  const isPropertyRoute = location.pathname.includes("/property");



  return (
    <>
      <aside className="sidebar">
        <div className="logo">
          <Image src={Logo} alt="logo" width={116} height={44} />
        </div>

        <ul>
          <li className={isAgentRoute ? "mb-3 activeMenuAdmin" : "mb-3"} onClick={()=>navigate('/agent/manage-estate-agent')}>
            <div
              className="d-flex align-items-center"
              style={{ width: "100%", height: "auto" }}
            >
              <span style={{ fontWeight: 600 }}>Manage Estate Agents</span>
            </div>
          </li>
          <li className={isSolicitorRoute ? "mb-3 activeMenuAdmin" : "mb-3"} onClick={()=>navigate('/solicitor/manage-solicitor')}>
            <div
              className="d-flex align-items-center"
              style={{ width: "100%", height: "auto" }}
            >
              <span style={{ fontWeight: 600 }}>Manage Solicitors</span>
            </div>
          </li>
          <li className={isSellerRoute ? "mb-3 activeMenuAdmin" : "mb-3"} onClick={()=>navigate('/seller/manage-seller')}>
            <div
              className="d-flex align-items-center"
              style={{ width: "100%", height: "auto" }}
            >
              <span style={{ fontWeight: 600 }}>Manage Sellers</span>
            </div>
          </li>
          <li className={isBuyerRoute ? "mb-3 activeMenuAdmin" : "mb-3"} onClick={()=>navigate('/buyer/manage-buyer')}>
            <div
              className="d-flex align-items-center"
              style={{ width: "100%", height: "auto" }}
            >
              <span style={{ fontWeight: 600 }}>Manage Buyers</span>
            </div>
          </li>
          <li className={isPropertyRoute ? "mb-3 activeMenuAdmin" : "mb-3"} onClick={()=>navigate('/property/manage-property')}>
            <div
              className="d-flex align-items-center"
              style={{ width: "100%", height: "auto" }}
            >
              <span style={{ fontWeight: 600 }}>Manage Properties</span>
            </div>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default AdminSidebar;
