import PageLayout from "../../compoments/layout/layout";
import HeaderDashboard from "../../compoments/Header";
import AdminSidebar from "../../compoments/layout/sidebar/admin";
import DataTable from "react-data-table-component";
import { ManageSellerColumn } from "../../Constant";
import { AdminTrash } from "../../compoments/icons/AdminTrash";
import { AdminView } from "../../compoments/icons/AdminView";
import { PlusIcon } from "../../compoments/icons/PlusIcon";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { sellersDetailsApi } from "../../apies/sellerApi";
import Loader from "../../compoments/LoaderBtn";

const ManageSeller = () => {
  const navigate = useNavigate();
  const [sellerList, setSellerList] = useState([]);
  useEffect(() => {
    const getSellerdFun = async () => {
      try {
        const response = await sellersDetailsApi();
        const mapped = response.map((seller, i) => {
          return {
            srNo: i + 1,
            name: seller?.seller?.name,
            solicitor: seller.address?.solicitor?.name || "-",
            estateAgent: seller.address?.estateAgent?.name || "-",
            email: seller?.seller?.email,
            propertyLoaction: seller?.address?.name || "-",
            action: (
              <div className="d-flex align-items-center g-3">
                {seller?.address && (
                  <button
                    className="btnViewDetail me-2"
                    onClick={() =>
                      navigate("/seller/view-seller-detail", {
                        state: {
                          addressName: seller?.address?.name,
                          addressId: seller?.address?._id,
                          sellerName: seller?.seller?.name,
                        },
                      })
                    }
                  >
                    <span>
                      <AdminView />
                    </span>
                    View
                  </button>
                )}
              </div>
            ),
          };
        });
        setSellerList(mapped);
      } catch (error) {
        console.log(error);
      }
    };
    getSellerdFun();
  }, []);

  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 1000);
  return (
    <>
      {loading && <Loader />}
      <PageLayout>
        <section className="pageWrapper">
          <div className="sidebarContent">
            <AdminSidebar />
          </div>
          <section className="mainContent">
            <HeaderDashboard />
            <div className="customCard">
              <div className="pageBody">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div>
                    <div className="heading mb-2">Manage Sellers</div>
                    <p className="mb-0">
                      As an admin, you are allowed to manage sellers and view
                      their activities across each property.
                    </p>
                  </div>
                  <div>
                    <button
                      className="btnPrimary"
                      onClick={() => navigate("/seller/register-seller")}
                    >
                      <span className="me-2">
                        <PlusIcon />
                      </span>
                      Register Seller
                    </button>
                  </div>
                </div>
                <div className="dataTableContainer mt-4">
                  <DataTable columns={ManageSellerColumn} data={sellerList} />
                </div>
              </div>
            </div>
          </section>
        </section>
      </PageLayout>
    </>
  );
};

export default ManageSeller;
