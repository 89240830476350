import apiClient from ".";

// list of buyers and their details
export const listSolictorApis =  async () => {
  const response = await apiClient.get(`admin/solicitors`);
  return response.data;
};

// Get list of address against a single solicitor
export const solicitorAddressApi =  async (id) => {
  const response = await apiClient.get(`admin/solicitor/address/${id}`);
  return response.data;
};

// Get list of address against a single solicitor
export const sellersWithNoSolicitorApi =  async () => {
  const response = await apiClient.get(`admin/sellers/no-solicitor`);
  return response.data;
};

//Register new solicitor with seller
export const registerSolicitor =  async (body) => {
  const response = await apiClient.post(`admin/solicitor/register`,{...body});
  return response.data;
};


//Register new solicitor with seller
export const deleteSolicitorApi =  async (solicitorId) => {
  const response = await apiClient.delete(`admin/solicitor/delete/${solicitorId}`);
  return response.data;
};