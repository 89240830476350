export const WarnIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.1363 21.227L20.603 5.86699C19.4563 3.80033 17.8697 2.66699 16.123 2.66699C14.3763 2.66699 12.7897 3.80033 11.643 5.86699L3.10968 21.227C2.02968 23.187 1.90968 25.067 2.77635 26.547C3.64302 28.027 5.34968 28.8403 7.58968 28.8403H24.6563C26.8963 28.8403 28.603 28.027 29.4697 26.547C30.3363 25.067 30.2163 23.1737 29.1363 21.227ZM15.123 12.0003C15.123 11.4537 15.5763 11.0003 16.123 11.0003C16.6697 11.0003 17.123 11.4537 17.123 12.0003V18.667C17.123 19.2137 16.6697 19.667 16.123 19.667C15.5763 19.667 15.123 19.2137 15.123 18.667V12.0003ZM17.0697 23.6137C17.003 23.667 16.9363 23.7203 16.8697 23.7737C16.7897 23.827 16.7097 23.867 16.6297 23.8937C16.5497 23.9337 16.4697 23.9603 16.3763 23.9737C16.2963 23.987 16.203 24.0003 16.123 24.0003C16.043 24.0003 15.9497 23.987 15.8563 23.9737C15.7763 23.9603 15.6963 23.9337 15.6163 23.8937C15.5363 23.867 15.4563 23.827 15.3763 23.7737C15.3097 23.7203 15.243 23.667 15.1763 23.6137C14.9363 23.3603 14.7897 23.0137 14.7897 22.667C14.7897 22.3203 14.9363 21.9737 15.1763 21.7203C15.243 21.667 15.3097 21.6137 15.3763 21.5603C15.4563 21.507 15.5363 21.467 15.6163 21.4403C15.6963 21.4003 15.7763 21.3737 15.8563 21.3603C16.0297 21.3203 16.2163 21.3203 16.3763 21.3603C16.4697 21.3737 16.5497 21.4003 16.6297 21.4403C16.7097 21.467 16.7897 21.507 16.8697 21.5603C16.9363 21.6137 17.003 21.667 17.0697 21.7203C17.3097 21.9737 17.4563 22.3203 17.4563 22.667C17.4563 23.0137 17.3097 23.3603 17.0697 23.6137Z"
        fill="#D7CF10"
      />
    </svg>
  );
};
