import PageLayout from "../../compoments/layout/layout";
import HeaderDashboard from "../../compoments/Header";
import AdminSidebar from "../../compoments/layout/sidebar/admin";
import DataTable from "react-data-table-component";
import { PropertyAssignColumn } from "../../Constant";
import { AdminTrash } from "../../compoments/icons/AdminTrash";
import { AdminView } from "../../compoments/icons/AdminView";
import { AdminProperty } from "../../compoments/icons/AdminProperty";
import { PlusIcon } from "../../compoments/icons/PlusIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AssignModal from "../../compoments/modals/AssignModal";
import SuccessFullAssignModal from "../../compoments/modals/SuccessFullAssignModal";
import { ArrowIcon } from "../../compoments/icons/ArrowIcon";
import {
  asignPropertyToAgent,
  getAddressWithoutAgentApi,
} from "../../apies/agentApi";
import { toast } from "react-toastify";
import Loader from "../../compoments/LoaderBtn";

const AssignProperty = () => {
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showSuccessModal, setShowSuccessnModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { agentId, agentName } = location.state;
  const [addresses, setAddresses] = useState([]);
  let [addressId, setAddressId] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getAddressesFun = async () => {
      try {
        const res = await getAddressWithoutAgentApi();
        const mapped = res.map((address, i) => {
          return {
            srNo: i + 1,
            propertyLocation: address.name,
            seller: address?.seller?.name,
            solicitor: address?.solicitor?.name,
            price: `$${address.price}`,
            documents: (
              <button
                onClick={() =>
                  navigate("/agent/view-documents", {
                    state: {
                      addressName: address?.name,
                      addressId: address?._id,
                      agentName: agentName,
                    },
                  })
                }
                className="btnViewDetail me-2"
              >
                <span>
                  <AdminView />
                </span>
                View Details
              </button>
            ),
            assignProperty: (
              <button
                className="btnAssignProperty"
                onClick={() => {
                  setAddressId(address._id);
                  setShowAssignModal(true);
                }}
              >
                <span>
                  <AdminProperty />
                </span>
                Assign Property
              </button>
            ),
          };
        });
        setAddresses(mapped);
      } catch (error) {}
    };
    getAddressesFun();
  }, [showAssignModal]);
  const asignPropertyFun = async () => {
    setLoading(true)
    try {
      const res = await asignPropertyToAgent({
        addressId,
        estateAgentId: agentId,
        role: "estateAgent",
      });
      if (res.status === 200) {
        toast.success(res.message);
        setLoading(false)
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      setShowAssignModal(false);
    }
  };

  setTimeout(() => {
    setLoading(false);
  }, 1000);

  return (
    <>
      {loading && <Loader />}
      <PageLayout>
        <section className="pageWrapper">
          <div className="sidebarContent">
            <AdminSidebar />
          </div>
          <section className="mainContent">
            <HeaderDashboard />
            <div className="customCard">
              <div className="pageBody">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div>
                    <div
                      className="heading mb-1"
                      style={{ color: "var(--textColor)", fontSize: 14 }}
                    >
                     <Link
                        to={"/agent/manage-estate-agent"}
                        style={{
                          color: "var(--textColor)",
                          fontSize: 14,
                          textDecoration: "none",
                        }}
                      >
                        {" "}
                        Manage Estate Agents{" "}
                      </Link>
                      <span>
                        <ArrowIcon />
                      </span>
                      <span
                        className="mx-2 bold"
                        style={{ fontSize: "16px", color: "#000" }}
                      >
                        
                        Assign Property{" "}
                      </span>
                      <span>
                        <ArrowIcon />
                      </span>
                      <span
                        className="mx-2 bold"
                        style={{ fontSize: "16px", color: "#000" }}
                      >
                        {agentName}
                      </span>
                    </div>{" "}
                    <p className="mb-0">
                      As an admin, you are allowed to manage estate agents and
                      view their activities across each property.
                    </p>
                  </div>
                  <div>
                    <button
                      className="btnPrimary"
                      onClick={() => navigate("/agent/register-estate-agent")}
                    >
                      <span className="me-2">
                        <PlusIcon />
                      </span>
                      Register Estate Agent
                    </button>
                  </div>
                </div>
                <div className="dataTableContainer mt-4">
                  <DataTable columns={PropertyAssignColumn} data={addresses} />
                </div>
              </div>
            </div>
          </section>
        </section>

        {showAssignModal && (
          <AssignModal
            show={showAssignModal}
            onHide={() => setShowAssignModal(false)}
            submit={asignPropertyFun}
          />
        )}
        {/* {showSuccessModal && (
        <SuccessFullAssignModal
          show={showSuccessModal}
          onHide={() => setShowSuccessnModal(false)}
        />
      )} */}
      </PageLayout>
    </>
  );
};

export default AssignProperty;
