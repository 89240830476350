import React, { useState } from 'react';
import Select from 'react-select';



const SelectBox= ({ options, handleChange, selectedOption, value, name, defaultSelected }) => {
    
  return (
    <Select
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: '#f1f1f1',
          primary: '#222222',
        },
      })}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? '#e1e1e2' : '#e1e1e2',
          fontSize:  '14px',
          fontWeight: 400,
          fontFamily: "Open Sans, sans-serif",
          color: '#bdbdbd',
          border: '1px solid #e1e1e2',
          boxShadow: 'none',
          padding: '5px 12px',
          cursor: 'pointer',
          borderRadius: '35px',
        }),
      }}
      className={ 'w-100' }
      name={name}
      defaultValue={defaultSelected}
      options={options}
      value={selectedOption}
      onChange={handleChange}
      isSearchable={true}
    />
  );
};

export default SelectBox;
