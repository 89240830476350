export const ManageEstateAgentsColumn = [
  {
    name: "Sr. No",
    selector: (row) => row.srNo,
    width: "140px",
  },
  {
    name: "Agent Name",
    selector: (row) => row.name,
    minWidth: "150px",
  },
  {
    name: "Email Address",
    selector: (row) => row.email,
    minWidth: "250px",
  },
  {
    name: "Managing Properties",
    selector: (row) => row.manageProperty,
    minWidth: "200px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    minWidth: "400px",
  },
];

export const ManageSolicitorsColumn = [
  {
    name: "Sr. No",
    selector: (row) => row.srNo,
    width: "140px",
  },
  {
    name: "Solicitor Name",
    selector: (row) => row.name,
    width: "200px",
  },
  {
    name: "Email Address",
    selector: (row) => row.email,
    width: "280px",
  },
  {
    name: "Associated with Seller",
    selector: (row) => row.associatedWithSeller,
    width: "250px",
  },
  {
    name: "Managing Properties",
    selector: (row) => row.manageProperty,
    width: "250px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    minWidth: "200px",
  },
];

export const ViewAgentDetailsColumn = [
  {
    name: "Sr. No",
    selector: (row) => row.srNo,
    width: "140px",
  },
  {
    name: "Property Location",
    selector: (row) => row.propertyLocation,
    width: "400px",
  },
  {
    name: "Seller",
    selector: (row) => row.seller,
    width: "180px",
  },
  {
    name: "Buyer",
    selector: (row) => row.buyer,
    width: "180px",
  },
  {
    name: "Solicitor",
    selector: (row) => row.solicitor,
    width: "180px",
  },
  {
    name: "Price",
    selector: (row) => row.price,
    width: "180px",
  },
  {
    name: "Documents",
    selector: (row) => row.action,
    minWidth: "200px",
  },
];

export const PropertyAssignColumn = [
  {
    name: "Sr. No",
    selector: (row) => row.srNo,
    width: "140px",
  },
  {
    name: "Property Location",
    selector: (row) => row.propertyLocation,
    width: "400px",
  },
  {
    name: "Seller",
    selector: (row) => row.seller,
    width: "180px",
  },
  {
    name: "Solicitor",
    selector: (row) => row.solicitor,
    width: "180px",
  },
  {
    name: "Price",
    selector: (row) => row.price,
    width: "180px",
  },
  {
    name: "Documents",
    selector: (row) => row.documents,
    minWidth: "200px",
  },
  {
    name: "Assign Property",
    selector: (row) => row.assignProperty,
    minWidth: "200px",
  },
];

export const ViewSolicitorDetailsColumn = [
  {
    name: "Property Location",
    selector: (row) => row.propertyLocation,
    width: "400px",
  },
  {
    name: "Seller",
    selector: (row) => row.seller,
    width: "180px",
  },
  {
    name: "Buyer",
    selector: (row) => row.buyer,
    width: "180px",
  },
  {
    name: "Estate Agent",
    selector: (row) => row.estateAgent,
    width: "180px",
  },
  {
    name: "Price",
    selector: (row) => row.price,
    width: "180px",
  },
  {
    name: "Documents",
    selector: (row) => row.action,
    minWidth: "200px",
  },
];

export const ManageSellerColumn = [
  {
    name: "Sr. No",
    selector: (row) => row.srNo,
    width: "140px",
  },
  {
    name: "Seller Name",
    selector: (row) => row.name,
    width: "200px",
  },
  {
    name: "Solicitor",
    selector: (row) => row.solicitor,
    width: "200px",
  },
  {
    name: "Estate Agent",
    selector: (row) => row.estateAgent,
    width: "200px",
  },
  {
    name: "Email Address",
    selector: (row) => row.email,
    width: "250px",
  },
  {
    name: "Property Location",
    selector: (row) => row.propertyLoaction,
    width: "320px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    minWidth: "100px",
  },
];
export const ManageBuyerColumn = [
  {
    name: "Sr. No",
    selector: (row) => row.srNo,
    width: "140px",
  },
  {
    name: "Buyer Name",
    selector: (row) => row.name,
    width: "200px",
  },
  {
    name: "Solicitor",
    selector: (row) => row.solicitor,
    width: "200px",
  },
  {
    name: "Estate Agent",
    selector: (row) => row.estateAgent,
    width: "200px",
  },
  {
    name: "Email Address",
    selector: (row) => row.email,
    width: "250px",
  },
  {
    name: "Property Location",
    selector: (row) => row.propertyLoaction,
    width: "320px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    minWidth: "100px",
  },
];

export const ManagePropertyColumn = [
  {
    name: "Sr. No",
    selector: (row) => row.srNo,
    width: "80px",
  },
  {
    name: "Property Location",
    selector: (row) => row.propertyLoaction,
    width: "320px",
  },

  {
    name: "Seller",
    selector: (row) => row.seller,
    width: "150px",
  },
  {
    name: "Buyer",
    selector: (row) => row.buyer,
    width: "150",
  },
  {
    name: "Solicitor",
    selector: (row) => row.solicitor,
    width: "150",
  },
  {
    name: "Estate Agent",
    selector: (row) => row.estateAgent,
    width: "150px",
  },
  {
    name: "Price",
    selector: (row) => row.price,
    width: "150px",
  },

  {
    name: "Actions",
    selector: (row) => row.action,
    minWidth: "150px",
  },
];

export const roleOption = [
  { value: "en", label: "English (US)" },
  { value: "ch", label: "Chinese" },
  { value: "ja", label: "Japanese" },
  { value: "it", label: "Italian" },
  { value: "es", label: "Spanish" },
  { value: "fr", label: "French" },
  { value: "de", label: "German" },
  { value: "ru", label: "Russian" },
  { value: "ko", label: "Korean" },
];


export const docTypes = [
  "Identity",
  "Proof of Address",
  "Property Title Deeds",
  "TA6 Property Information Form",
  "Energy Performance Certificate - EPC",
  "TA10 Fittings + Contents Form",
  "Land Reg Info",
  "Building Regulations / FENSA Certificates",
  "Management Info Pack (Leasehold)",
  "Warranties",
  "Gas + Electric Certificates",
  "Planning Permission Certificates",
  "Leasehold / Freehold Information",
  "Signed Contracts",
  "Survey",
  // "Identity",
  "Proof Of Address",
  "Mortgage In Principle",
  "Proof of Funds",
  "Chain Details",
  "Proof of employment",
  "Signed Contracts",
  "Memorandum of Sale",
  "Identity Proof Check",
  "AML",
  "Contracts",
  "Buyer + Seller Solicitor Info",
  "Chain Information",
  "Searches",
  "Contracts"
]
