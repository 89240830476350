import AppRouter from "./Route";
import "./App.scss";
import PageLayout from "./compoments/layout/layout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";

function App({ children }) {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="dark"
      />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRouter>
            <PageLayout>
              <main className="content">{children}</main>
            </PageLayout>
          </AppRouter>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
