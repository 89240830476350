// src/api/authApi.js
import apiClient from './index';

// Agents list and related data
export const getAgentsListApi = async () => {
  const response = await apiClient.get('admin/agents');
  return response.data;
};

// list of verified addreses`
export const getVerifiedAddressApi =  async (agentId) => {
  const response = await apiClient.get(`admin/estate-agent/verified/addresses/${agentId}`);
  return response.data;
};

// list of unverified addreses`
export const getUnVerifiedAddressApi =  async (agentId) => {
  const response = await apiClient.get(`admin/estate-agent/unverified/addresses/${agentId}`);
  return response.data;
};


// list of addreses without agents`
export const getAddressWithoutAgentApi =  async () => {
  const response = await apiClient.get(`admin/addresses/no-agent`);
  return response.data;
};


// list of addreses without agents`
export const asignPropertyToAgent =  async (body) => {
  const response = await apiClient.post(`admin/agent/assign-property`,{...body});
  return response.data;
};

//Delete A single agent
export const deleteAgentApi =  async (userId) => {
  const response = await apiClient.delete(`admin/estate-agent/remove/${userId}`);
  return response.data;
};