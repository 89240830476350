import PageLayout from "../../compoments/layout/layout";
import HeaderDashboard from "../../compoments/Header";
import AdminSidebar from "../../compoments/layout/sidebar/admin";
import DataTable from "react-data-table-component";
import { ViewAgentDetailsColumn } from "../../Constant";
import { AdminView } from "../../compoments/icons/AdminView";
import { PlusIcon } from "../../compoments/icons/PlusIcon";
import { ArrowIcon } from "../../compoments/icons/ArrowIcon";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ApprovedIcon } from "../../compoments/icons/ApprovedIcon";
import {
  getUnVerifiedAddressApi,
  getVerifiedAddressApi,
} from "../../apies/agentApi";
import Loader from "../../compoments/LoaderBtn";

const ViewAgentDetails = () => {
  const [activeTab, setActiveTab] = useState("unverified");
  const [verifiedAddresses, setVerifiedAddresses] = useState([]);
  const [unverifiedAddresses, setUnVerifiedAddresses] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { agentId, agentName } = location.state;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifiedAddressesFun = async () => {
      try {
        const res = await getVerifiedAddressApi(agentId);
        const mapped = res.map((address, i) => {
          return {
            srNo: i + 1,
            propertyLocation: address.name,
            seller: address?.seller?.name ? address?.seller?.name : "-",
            buyer: address?.buyer?.name ? address?.buyer?.name : "-",
            solicitor: address?.seller?.solicitor?.name,
            price: address.price,
            action: (
              <>
                {activeTab == "unverified" ? (
                  <button
                    className="btnViewDetail me-2"
                    onClick={() => navigate("/agent/view-documents")}
                  >
                    <span>
                      <AdminView />
                    </span>
                    View Documents
                  </button>
                ) : (
                  <button className="btnApproved me-2">
                    <span>
                      <ApprovedIcon />
                    </span>
                    Approved
                  </button>
                )}
              </>
            ),
          };
        });
        setVerifiedAddresses(mapped);
      } catch (error) {
        console.log(error);
      }
    };
    const unVerifiedAddressesFun = async () => {
      try {
        const res = await getUnVerifiedAddressApi(agentId);
        const mapped = res.map((address, i) => {
          return {
            srNo: i + 1,
            propertyLocation: address.name,
            seller: address?.seller?.name ? address?.seller?.name : "-",
            buyer: address?.buyer?.name ? address?.buyer?.name : "-",
            solicitor: address?.seller?.solicitor?.name,
            price: address.price,
            action: (
              <>
                {activeTab == "unverified" ? (
                  <button
                    className="btnViewDetail me-2"
                    onClick={() =>
                      navigate("/agent/view-documents", {
                        state: {
                          addressName: address?.name,
                          addressId: address?._id,
                          agentName: address?.estateAgent?.name,
                        },
                      })
                    }
                  >
                    <span>
                      <AdminView />
                    </span>
                    View Documents
                  </button>
                ) : (
                  <button className="btnApproved me-2">
                    <span>
                      <ApprovedIcon />
                    </span>
                    Approved
                  </button>
                )}
              </>
            ),
          };
        });
        setUnVerifiedAddresses(mapped);
      } catch (error) {
        console.log(error);
      }
    };
    activeTab === "verified" && verifiedAddressesFun();
    activeTab === "unverified" && unVerifiedAddressesFun();
  }, [activeTab]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [activeTab]);
  return (
    <>
      {loading && <Loader />}

      <PageLayout>
        <section className="pageWrapper">
          <div className="sidebarContent">
            <AdminSidebar />
          </div>
          <section className="mainContent">
            <HeaderDashboard />
            <div className="customCard">
              <div className="pageBody">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div>
                    <div
                      className="heading mb-1"
                      style={{ color: "var(--textColor)", fontSize: 14 }}
                    >
                      <Link
                        to={"/agent/manage-estate-agent"}
                        style={{
                          color: "var(--textColor)",
                          fontSize: 14,
                          textDecoration: "none",
                        }}
                      >
                        {" "}
                        Manage Estate Agents{" "}
                      </Link>
                      <span>
                        <ArrowIcon />
                      </span>
                      <span
                        className="mx-2 bold text-capitalize"
                        style={{ fontSize: "16px", color: "#000" }}
                      >
                        {agentName}
                      </span>
                    </div>
                    <p className="mb-0">
                      View estate agent profile and the properties managed by
                      that agent.
                    </p>
                  </div>
                  <div>
                    <button
                      className="btnPrimary"
                      onClick={() => navigate("/agent/register-estate-agent")}
                    >
                      <span className="me-2">
                        <PlusIcon />
                      </span>
                      Register Estate Agent
                    </button>
                  </div>
                </div>

                <div className="mb-4">
                  <div>
                    <button
                      className={
                        activeTab === "unverified"
                          ? "btnPrimaryTab"
                          : "btnPrimaryOutline"
                      }
                      onClick={() => setActiveTab("unverified")}
                    >
                      Unverified Properties
                    </button>
                    <button
                      className={
                        activeTab === "verified"
                          ? "btnPrimaryTab mx-3"
                          : "mx-3 btnPrimaryOutline"
                      }
                      onClick={() => setActiveTab("verified")}
                    >
                      Verified Properties
                    </button>
                  </div>
                </div>
                <div className="dataTableContainer mt-4">
                  {activeTab === "unverified" ? (
                    <DataTable
                      columns={ViewAgentDetailsColumn}
                      data={unverifiedAddresses}
                    />
                  ) : (
                    <DataTable
                      columns={ViewAgentDetailsColumn}
                      data={verifiedAddresses}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </section>
      </PageLayout>
    </>
  );
};

export default ViewAgentDetails;
