import apiClient from ".";

// get list of documents against address
export const getDocsAgainstAddressApi =  async (addressId) => {
    const response = await apiClient.get(`doc/all-doc/${addressId}`);
    return response.data;
  };


  // Register new user
export const registerUserApi =  async (body) => {
    const response = await apiClient.post(`auth/register`,{...body});
    return response.data;
  };

  // delete user

  export const deleteUserApi = async(userId)=>{
    const response = await apiClient.delete(`admin/user/${userId}`);
    return response.data;
  }

  // Get Documents against an property (address)
  export const docAgainstAddressApi = async(addressId)=>{
    const response = await apiClient.get(`doc/all-doc/${addressId}`);
    return response.data;
  }


// export const asignProptertyApi =  async (body) => {
//     const response = await apiClient.post(`auth/register`,{...body});
//     return response.data;
//   };