import PageLayout from "../../compoments/layout/layout";
import HeaderDashboard from "../../compoments/Header";
import AdminSidebar from "../../compoments/layout/sidebar/admin";
import DataTable from "react-data-table-component";
import { docTypes, ViewAgentDetailsColumn } from "../../Constant";
import { AdminView } from "../../compoments/icons/AdminView";
import { PlusIcon } from "../../compoments/icons/PlusIcon";
import { ArrowIcon } from "../../compoments/icons/ArrowIcon";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EyeIcon } from "../../compoments/icons/EyeIcon";
import { WarnIcon } from "../../compoments/icons/WarnIcon";
import PreviewFileModal from "../../compoments/modals/PreviewFileModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { docAgainstAddressApi } from "../../apies/commonApies";
import Loader from "../../compoments/LoaderBtn";

const ViewPropertyDocuments = () => {
  const [previewModal, setPreviewModal] = useState(false);
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [docList, setDocList] = useState([]);
  const location = useLocation();
  const { addressId, addressName } = location.state;
  const [unavailableDoc, setUnavailableDoc] = useState([])
  
  useEffect(() => {
    const getDocsFun = async () => {
      const res = await docAgainstAddressApi(addressId);
      // available doc types
      const availableDoc = res.documents.map((i)=> i.docType)
      // unavailable doc types
      const unaavailable = docTypes.filter((i)=> !availableDoc.includes(i))
      setUnavailableDoc(unaavailable)
      setDocList(res.documents);
    };
    getDocsFun();
  }, []);

  setTimeout(() => {
    setLoading(false)
  }, 1000);
  return (
    <>
    {loading && <Loader/> }

    <PageLayout>
      <section className="pageWrapper">
        <div className="sidebarContent">
          <AdminSidebar />
        </div>
        <section className="mainContent">
          <HeaderDashboard />
          <div className="customCard">
            <div className="pageBody">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                  <div
                    className="heading mb-2"
                    style={{ color: "var(--textColor)", fontSize: 14 }}
                  >
                    
                    <Link
                      to={"/property/manage-property"}
                      style={{
                        color: "var(--textColor)",
                        fontSize: 14,
                        textDecoration: "none",
                      }}
                    >
                      Manage Properties{" "}
                    </Link>

                    <span>
                      <ArrowIcon />
                    </span>
                    <span style={{ fontSize: "16px", color: "#000" }}>{addressName}</span>
                  </div>
                  <p className="mb-0">
                    As an admin, you are allowed to manage properties.{" "}
                  </p>
                </div>
              </div>

              <Row className="mt-4">
                { 
                  [...docList,...unavailableDoc].map((doc) => {
                    return (
                      <Col key={doc._id} md={3} className="mb-3">
                        <div className="documentCard">
                          <div
                            className={
                              typeof doc !=='object' ? 'customCardYellow':  doc.isEdit ? "customCardDark" : "customCardRed"
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setFile(doc?.url);
                              setFileName(doc.originalName);
                              setPreviewModal(true);
                              // setTitle(item.docType);
                            }}
                          >
                            {typeof doc !=='object'  ? <WarnIcon/>: <EyeIcon />}
                            {typeof doc ==='object' ? <p className="mb-1 mt-2">Preview PDF File</p>:
                             <p className="mb-1 mt-2">No File Uploaded</p>
                            }
                          </div>
                        </div>
                        <div className="cardName mt-1 py-2 d-flex align-items-start justify-content-center">
                          { typeof doc ==='object' ? doc?.docType :doc}
                          {typeof doc ==='object' && !doc.isEdit && (
                            <span className="text-red mx-1 text-nowrap">
                              {" "}
                              (Needs Approval)
                            </span>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
            </div>
          </div>
        </section>
      </section>

      {previewModal && (
        <PreviewFileModal
          show={previewModal}
          onHide={() => setPreviewModal(false)}
            file={file}
            // download={() => handleDownload(file, fileName)}
            // btnLoading={btnLoading}
            title={title}
        />
      )}
    </PageLayout>
    </>
  );
};

export default ViewPropertyDocuments;
