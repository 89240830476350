export const AdminView = () => {
  return (
    <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1693 6.10044C12.6293 3.68044 10.3759 2.28711 8.0026 2.28711C6.81594 2.28711 5.6626 2.63378 4.60927 3.28044C3.55594 3.93378 2.60927 4.88711 1.83594 6.10044C1.16927 7.14711 1.16927 8.84711 1.83594 9.89378C3.37594 12.3204 5.62927 13.7071 8.0026 13.7071C9.18927 13.7071 10.3426 13.3604 11.3959 12.7138C12.4493 12.0604 13.3959 11.1071 14.1693 9.89378C14.8359 8.85378 14.8359 7.14711 14.1693 6.10044ZM8.0026 10.6938C6.50927 10.6938 5.30927 9.48711 5.30927 8.00044C5.30927 6.51378 6.50927 5.30711 8.0026 5.30711C9.49594 5.30711 10.6959 6.51378 10.6959 8.00044C10.6959 9.48711 9.49594 10.6938 8.0026 10.6938Z"
      fill="#52C1FF"
    />
    <path
      d="M8.00156 6.09375C6.9549 6.09375 6.10156 6.94708 6.10156 8.00042C6.10156 9.04708 6.9549 9.90042 8.00156 9.90042C9.04823 9.90042 9.90823 9.04708 9.90823 8.00042C9.90823 6.95375 9.04823 6.09375 8.00156 6.09375Z"
      fill="#52C1FF"
    />
  </svg>
  );
};
