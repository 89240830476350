import apiClient from './index';

// Get List of propterties
export const listOfPropertiesApi = async () => {
  const response = await apiClient.get('admin/properties');
  return response.data;
};

// get list of seller that does't assigned with any property (address)
export const sellersApi = async () => {
  const response = await apiClient.get('admin/sellers');
  return response.data;
};


// register New Property (address)
export const registerProperty = async (body) => {
  const response = await apiClient.post('admin/regiser-proptery',{...body});
  return response.data;
};