import axios from "axios";
import { logout } from "../features/userSlice";

// Create an axios instance
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Base API URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Verify if baseURL is correctly loaded
console.log("Base", process.env.REACT_APP_API_URL); // Debug the base URL

// Set up request interceptors for token authorization
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Get token from localStorage or Redux
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Attach token to headers
      console.log("Token attached:", token); // Debug token attachment
    } else {
      console.log("No token found");
    }
    return config;
  },
  (error) => {
    console.error("Error in request:", error); // Debug request error
    return Promise.reject(error);
  }
);

// Set up response interceptors for handling errors globally
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle error response globally if needed
    if (error.response && error.response.status === 401) {
      console.log("Unauthorized access - Redirect to login");
      // You could redirect to login here or handle token refresh
      localStorage.clear();
    }
    console.error("Error in response:", error); // Debug response error
    return Promise.reject(error);
  }
);

export default apiClient;
