

function PageLayout({ children }) {
  return (
    <>
        <main className="content">{children}</main>
    </>
  );
}

export default PageLayout;
