export const HomeProperty = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3307 17.7083H17.4974V8.3166C17.4974 7.79993 17.2641 7.3166 16.8557 6.99993L15.8307 6.19993L15.8141 4.15827C15.8141 3.69993 15.4391 3.33327 14.9807 3.33327H12.1391L11.0224 2.4666C10.4224 1.9916 9.5724 1.9916 8.9724 2.4666L3.13906 6.99993C2.73073 7.3166 2.4974 7.79994 2.4974 8.30827L2.45573 17.7083H1.66406C1.3224 17.7083 1.03906 17.9916 1.03906 18.3333C1.03906 18.6749 1.3224 18.9583 1.66406 18.9583H18.3307C18.6724 18.9583 18.9557 18.6749 18.9557 18.3333C18.9557 17.9916 18.6724 17.7083 18.3307 17.7083ZM5.41406 10.6249V9.37494C5.41406 8.9166 5.78906 8.5416 6.2474 8.5416H7.91406C8.3724 8.5416 8.7474 8.9166 8.7474 9.37494V10.6249C8.7474 11.0833 8.3724 11.4583 7.91406 11.4583H6.2474C5.78906 11.4583 5.41406 11.0833 5.41406 10.6249ZM12.0807 17.7083H7.91406V15.4166C7.91406 14.7249 8.4724 14.1666 9.16406 14.1666H10.8307C11.5224 14.1666 12.0807 14.7249 12.0807 15.4166V17.7083ZM14.5807 10.6249C14.5807 11.0833 14.2057 11.4583 13.7474 11.4583H12.0807C11.6224 11.4583 11.2474 11.0833 11.2474 10.6249V9.37494C11.2474 8.9166 11.6224 8.5416 12.0807 8.5416H13.7474C14.2057 8.5416 14.5807 8.9166 14.5807 9.37494V10.6249Z"
        fill="white"
      />
    </svg>
  );
};
