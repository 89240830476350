import { Modal } from "react-bootstrap";
import { TrashIcon } from "../icons/TrashIcon";

const DeleteModal = ({ show, onHide ,title,onDelete}) => {
  return (
    <>
      <Modal
        show={show}
        className="uploadModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4>{title}</h4>
          <p>
            Are you sure that you want to delete this Agent. Confirm your
            action.
          </p>

          <div className="d-flex align-items-center mt-3 justify-content-center">
            <button className="btnGray" onClick={onHide}>
              Cancel
            </button>
            <button onClick={onDelete} className="btnDanger">
              {" "}
              <span className="me-1">
                <TrashIcon />
              </span>
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteModal;
