export const ArrowIcon = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.47469 2.53996L7.73469 5.79996C8.11969 6.18496 8.11969 6.81496 7.73469 7.19996L4.47469 10.46"
        stroke="black"
        stroke-opacity="0.75"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
