import PageLayout from "../../compoments/layout/layout";
import HeaderDashboard from "../../compoments/Header";
import AdminSidebar from "../../compoments/layout/sidebar/admin";
import Loader from "../../compoments/LoaderBtn";
import DataTable from "react-data-table-component";
import { ManageEstateAgentsColumn } from "../../Constant";
import { AdminTrash } from "../../compoments/icons/AdminTrash";
import { AdminView } from "../../compoments/icons/AdminView";
import { AdminProperty } from "../../compoments/icons/AdminProperty";
import { PlusIcon } from "../../compoments/icons/PlusIcon";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DeleteModal from "../../compoments/modals/DeleteModal";
import { deleteAgentApi, getAgentsListApi } from "../../apies/agentApi";
import { toast } from "react-toastify";

const ManageEstateAgent = () => {
  const [deleteAgent, setDeleteAgent] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [agent, setAgent] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const agentList = async () => {
      try {
        const res = await getAgentsListApi();
        // Map response to desired data structure
        const mappedData = res.map((agent, index) => ({
          srNo: index + 1,
          name: agent.name,
          email: agent.email,
          manageProperty:
            agent.addressCount === 0 ? (
              <button
                className="btnAssignProperty"
                onClick={() =>
                  navigate("/agent/assign-property", {
                    state: {
                      agentId: agent._id,
                      agentName: agent.name,
                    },
                  })
                }
              >
                <span>
                  <AdminProperty />
                </span>
                Assign Property
              </button>
            ) : (
              <>{agent.addressCount}</>
            ),
          action: (
            <div className="d-flex align-items-center g-3">
              {agent.addressCount > 0 ? (
                <button
                  className="btnViewDetail me-2"
                  onClick={() =>
                    navigate("/agent/view-agent-detail", {
                      state: {
                        agentId: agent._id,
                        agentName: agent.name,
                      },
                    })
                  }
                >
                  <span>
                    <AdminView />
                  </span>
                  View Details
                </button>
              ) : (
                <div style={{ width: "150px" }}>-</div>
              )}
              <button
                className="btnDeleteAgent me-2"
                onClick={() => {
                  setAgent(agent);
                  setDeleteAgent(true);
                }}
              >
                <span>
                  <AdminTrash />
                </span>
                Delete Agent
              </button>
            </div>
          ),
        }));

        setData(mappedData);
      } catch (error) {
        console.error("Failed to fetch agent list", error);
      }
    };
    agentList();
  }, [navigate, deleteAgent]);
  const deleteAgentFun = async () => {
    try {
      const res = await deleteAgentApi(agent._id);
      if (res.status === 200) {
        toast.success(res.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDeleteAgent(false);
    }
  };

  setTimeout(() => {
    setLoading(false)
  }, 1000);
  return (
    <>
    {loading && <Loader /> }
    <PageLayout>
      <section className="pageWrapper">
        <div className="sidebarContent">
          <AdminSidebar />
        </div>
        <section className="mainContent">
          <HeaderDashboard />
          <div className="customCard">
            <div className="pageBody">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                  <div className="heading mb-1">Manage Estate Agents</div>
                  <p className="mb-0">
                    As an admin, you are allowed to manage estate agents and
                    view their activities across each property.
                  </p>
                </div>
                <div>
                  <button
                    className="btnPrimary"
                    onClick={() => navigate("/agent/register-estate-agent")}
                  >
                    <span className="me-2">
                      <PlusIcon />
                    </span>
                    Register Estate Agent
                  </button>
                </div>
              </div>
              <div className="dataTableContainer table-responsive mt-4">
                <DataTable
                  className="dataTable"
                  columns={ManageEstateAgentsColumn}
                  data={data}
                  responsive={true}
                  overflowX={true} // Enable scrolling on small screens
                />
              </div>
            </div>
          </div>
        </section>

        {deleteAgent && (
          <DeleteModal
            show={deleteAgent}
            onHide={() => setDeleteAgent(false)}
            title="Delete Estate Agent"
            onDelete={deleteAgentFun}
          />
        )}
      </section>
    </PageLayout>
    </>
  );
};

export default ManageEstateAgent;
