import PageLayout from "../../compoments/layout/layout";
import HeaderDashboard from "../../compoments/Header";
import AdminSidebar from "../../compoments/layout/sidebar/admin";
import { useNavigate } from "react-router-dom";
import { ArrowIcon } from "../../compoments/icons/ArrowIcon";
import { Col, Form, Row } from "react-bootstrap";
import SuccessFullRegisterModal from "../../compoments/modals/SuccessFullRegisterModal";
import { useState } from "react";
import { toast } from "react-toastify";
import { registerUserApi } from "../../apies/commonApies";
import Loader from "../../compoments/LoaderBtn";

const RegisterSeller = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [formData, setFormData] = useState({
    fullName:"",
    name: "",
    email: "",
    password: "",
    role: "seller",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true);
    try {
      const response = await registerUserApi({
        name: formData.name,
        email: formData.email,
        password: formData.password,
        role: "seller",
      });
      if (response.status === 201) {
        setShowRegisterModal(true);
        setFormData({
          fullName:"",
          name: "",
          email: "",
          password: "",
          role: "seller",
        });
        setLoading(false);
      } else {
        const errorData = await response.json();
        toast.error(errorData.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("An error occurred:", error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      {loading && <Loader />}
      <PageLayout>
        <section className="pageWrapper">
          <div className="sidebarContent">
            <AdminSidebar />
          </div>
          <section className="mainContent">
            <HeaderDashboard />
            <div className="customCard">
              <div className="pageBody">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div>
                    <div
                      className="heading mb-1"
                      style={{ color: "var(--textColor)", fontSize: 14 }}
                    >
                      Manage Seller{" "}
                      <span>
                        <ArrowIcon />
                      </span>
                      <span
                        className="mx-2 bold"
                        style={{ fontSize: "16px", color: "#000" }}
                      >
                        Register Seller{" "}
                      </span>
                    </div>

                    <p className="mb-0">
                      Register new seller into the platform and let them manage
                      their property sale.
                    </p>
                  </div>
                </div>
                <hr className="mb-4" style={{ borderColor: "#bbb" }} />

                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        value={formData.fullName}
                        type="text"
                        placeholder="Enter full name of the seller"
                        name="fullName"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        value={formData.name}
                        type="text"
                        placeholder="Set username for the seller"
                        name="name"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        value={formData.email}
                        type="text"
                        placeholder="Enter email address of the seller"
                        name="email"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Set Password</Form.Label>
                      <Form.Control
                        type="text"
                        value={formData.password}
                        placeholder="Set Password for the seller"
                        name="password"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <button
                  type="button"
                  className="mt-3 btnPrimary"
                  onClick={handleSubmit}
                >
                  Register Seller
                </button>
              </div>
            </div>
          </section>

          {showRegisterModal && (
            <SuccessFullRegisterModal
              show={showRegisterModal}
              onHide={() => setShowRegisterModal(false)}
              role="seller"
            />
          )}
        </section>
      </PageLayout>
    </>
  );
};

export default RegisterSeller;
