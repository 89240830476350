import { Dropdown, Form, Image } from "react-bootstrap";
import { SearchIcon } from "./icons/SearchIcon";
import { GreenIcon } from "./icons/GreenIcon";
import { RedIcon } from "./icons/RedIcon";
import Avatar from './../assets/images/Avatar.png'
import { ArrowDownMenu } from "./icons/ArrowDownMenu";
import { useState } from "react";
import LogoutModal from "./modals/LogoutModal";
import { useNavigate } from "react-router-dom";
import { BlueIcon } from "./icons/BlueIcon";

const HeaderDashboard = () => {
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate();
  
  
  
  
  return (
    <>
      <div className="customCard">
        <div className="pageHeader">
          <div className="position-relative">
            <Form.Control type="text" placeholder="Search" autoComplete="off" />
            <span className="fieldIcon">
              <SearchIcon />
            </span>
          </div>
          <div className="d-flex align-items-center">
            <Dropdown className="profileDropdown">
              <Dropdown.Toggle id="dropdown-basic" className="">
                <Image src={Avatar} alt="" />
                <span className="">
                  <ArrowDownMenu />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#" onClick={() => setLogoutModal(true)}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      {logoutModal && (
        <LogoutModal show={logoutModal} onHide={() => setLogoutModal(false)} />
      )}
    </>
  );
};

export default HeaderDashboard;
