import { Form, Image } from "react-bootstrap";
import Logo from "./../../assets/images/BlackLogo.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { toast } from "react-toastify";
import { useDispatch,useSelector } from "react-redux";
import { loginUser } from "../../features/userSlice";

const Step1 = () => {

  const dispatch = useDispatch();
  const {token} = useSelector((state) => state.user); 
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });


  useEffect(() => {
    if (token) {
      navigate("/agent/manage-estate-agent");
    }

 
  }, [token]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogin = async () => {
    try {
      dispatch(loginUser({...formData,role:"admin"})); 
    } catch (error) {
      console.log(error)
    };
  };
  return (
    <>
      <div className="Step6Splash">
        <section className="stepHow">
          <div
            style={{
              height: "auto",
              width: 600,
              background: "#fff",
              borderRadius: 32,
              border: "1px solid var(--cardBorder)",
            }}
            className="p-5"
          >
            <div className="text-center">
              <Image src={Logo} alt="Company Logo" width={170} />
            </div>

            <h2 className="mt-5 text-center" style={{ fontWeight: 700 }}>
              Login as an Admin
            </h2>

            <Form className="px-3">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username here"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password here"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </Form.Group>

              <button
                type="button"
                className="w-100 mt-3 btnPrimary"
                onClick={handleLogin}
              >
                LOGIN
                {/* {loading? "LOADING..." :"LOGIN"} */}
              </button>
            </Form>
          </div>
        </section>
      </div>
    </>
  );
};

export default Step1;
