export const AdminTrash = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0491 3.48634C12.9757 3.37967 11.9024 3.29967 10.8224 3.23967V3.23301L10.6757 2.36634C10.5757 1.75301 10.429 0.833008 8.86905 0.833008H7.12238C5.56905 0.833008 5.42238 1.71301 5.31572 2.35967L5.17572 3.21301C4.55572 3.25301 3.93572 3.29301 3.31572 3.35301L1.95572 3.48634C1.67572 3.51301 1.47572 3.75967 1.50238 4.03301C1.52905 4.30634 1.76905 4.50634 2.04905 4.47967L3.40905 4.34634C6.90238 3.99967 10.4224 4.13301 13.9557 4.48634C13.9757 4.48634 13.989 4.48634 14.0091 4.48634C14.2624 4.48634 14.4824 4.29301 14.5091 4.03301C14.529 3.75967 14.3291 3.51301 14.0491 3.48634Z"
        fill="#EF0303"
      />
      <path
        d="M12.8211 5.42699C12.6611 5.26033 12.4411 5.16699 12.2145 5.16699H3.7878C3.56114 5.16699 3.33447 5.26033 3.18114 5.42699C3.0278 5.59366 2.94114 5.82033 2.95447 6.05366L3.3678 12.8937C3.44114 13.907 3.53447 15.1737 5.86114 15.1737H10.1411C12.4678 15.1737 12.5611 13.9137 12.6345 12.8937L13.0478 6.06033C13.0611 5.82033 12.9745 5.59366 12.8211 5.42699ZM9.1078 11.8337H6.8878C6.61447 11.8337 6.3878 11.607 6.3878 11.3337C6.3878 11.0603 6.61447 10.8337 6.8878 10.8337H9.1078C9.38113 10.8337 9.6078 11.0603 9.6078 11.3337C9.6078 11.607 9.38113 11.8337 9.1078 11.8337ZM9.6678 9.16699H6.33447C6.06114 9.16699 5.83447 8.94033 5.83447 8.66699C5.83447 8.39366 6.06114 8.16699 6.33447 8.16699H9.6678C9.94113 8.16699 10.1678 8.39366 10.1678 8.66699C10.1678 8.94033 9.94113 9.16699 9.6678 9.16699Z"
        fill="#EF0303"
      />
    </svg>
  );
};
