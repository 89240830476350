import PageLayout from "../../compoments/layout/layout";
import HeaderDashboard from "../../compoments/Header";
import AdminSidebar from "../../compoments/layout/sidebar/admin";
import DataTable from "react-data-table-component";
import { AdminTrash } from "../../compoments/icons/AdminTrash";
import { AdminView } from "../../compoments/icons/AdminView";
import { AdminProperty } from "../../compoments/icons/AdminProperty";
import { PlusIcon } from "../../compoments/icons/PlusIcon";
import { Link, useNavigate } from "react-router-dom";
import { ArrowIcon } from "../../compoments/icons/ArrowIcon";
import { Col, Form, Row } from "react-bootstrap";
import SuccessFullRegisterModal from "../../compoments/modals/SuccessFullRegisterModal";
import { useEffect, useState } from "react";
// import { roleOption } from "../../Constant";
import SelectBox from "../../compoments/SelectBox";
import { registerProperty, sellersApi } from "../../apies/proptertApies";
import { toast } from "react-toastify";
import Loader from "../../compoments/LoaderBtn";

const RegisterProperty = () => {
  const navigate = useNavigate();

  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    name: "",
    price: 0,
  });

  const inputChnage = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSelectChange = (selectedOption, fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };
  useEffect(() => {
    const getSellersFun = async () => {
      try {
        const res = await sellersApi();
        const mapped = res.map((seller) => {
          return { value: seller._id, label: seller.name };
        });
        setSellers(mapped);
      } catch (error) {
        console.log(error);
      }
    };
    getSellersFun();
  }, [showRegisterModal]);
  const registerProptery = async () => {
    setLoading(true)
    try {
      const res = await registerProperty(formData);
      if (res.status == 200) {
        setShowRegisterModal(true);
        setLoading(false)
        setFormData({
          name: "",
          price: 0,
        })
      }
    } catch (error) {
      setLoading(false)
      console.log("error", error);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
    {loading && <Loader /> }
      <PageLayout>
        <section className="pageWrapper">
          <div className="sidebarContent">
            <AdminSidebar />
          </div>
          <section className="mainContent">
            <HeaderDashboard />
            <div className="customCard">
              <div className="pageBody">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div>
                    <div
                      className="heading mb-2"
                      style={{ color: "var(--textColor)", fontSize: 14 }}
                    >
                      <Link
                        to={"/property/manage-property"}
                        style={{
                          color: "var(--textColor)",
                          fontSize: 14,
                          textDecoration: "none",
                        }}
                      >
                        Manage Properties{" "}
                      </Link>
                      <span>
                        <ArrowIcon />
                      </span>
                      <span
                        className="mx-2 bold"
                        style={{ fontSize: "16px", color: "#000" }}
                      >
                        Register Property{" "}
                      </span>
                    </div>

                    <p className="mb-0">
                      Register new property into the platform.{" "}
                    </p>
                  </div>
                </div>
                <hr className="mb-4" style={{ borderColor: "#bbb" }} />

                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Property Name</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={inputChnage}
                        placeholder="Enter name of the property"
                        name="name"
                        value={formData?.name}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Selling Price</Form.Label>
                      <Form.Control
                        onChange={inputChnage}
                        type="number"
                        placeholder="Enter selling price for the property"
                        name="price"
                        value={formData?.price > 0 ? formData?.price: ""}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Associated Seller</Form.Label>
                      <SelectBox
                        name="assignProperty"
                        value={formData.assignProperty}
                        handleChange={(selectedOption) =>
                          handleSelectChange(selectedOption.value, "seller")
                        }
                        options={sellers}
                        defaultSelected={sellers[0]}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <button
                  type="button"
                  className="mt-3 btnPrimary"
                  onClick={() => registerProptery()}
                >
                  Register Property
                </button>
              </div>
            </div>
          </section>

          {showRegisterModal && (
            <SuccessFullRegisterModal
              show={showRegisterModal}
              onHide={() => setShowRegisterModal(false)}
              role="property"
            />
          )}
        </section>
      </PageLayout>
    </>
  );
};

export default RegisterProperty;
